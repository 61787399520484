import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="IRStrat II"/>
            <Banner title="IRStrat II"/>
            <section className="welcome_section_area propuesta">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">It focuses on the optimum structuring and communication of your strategic
                        message (investment thesis, background and growth strategy) aimed at maximizing the valuation and trading of your
                        securities in the financial markets, comprising:</p>
                    </div>
                    <div className="row servicios service_right_sidebar p-0 mb-5">
                        <div className="col-lg-12">
                            <div id="accordion"
                                 className="panel-group container">
                                 <div className="row">
                                <div className="col-md-6 mb-5">
                                <div className="" role="tab">
                                        <h4 className="panel-title">
                                        Market reporting and day-to-day
                                        communications

                                        </h4>
                                    </div>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Earnings release drafting and circle-up
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Annual report and regulatory filings drafting and circle-up
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Investors presentations drafting and quarterly updating
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Quarterly updates of investment fact sheet
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Consultancy on communication and development of annual guidance
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Press releases drafting
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Social media management (C-Suite’s Twitter and Linkedin)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Contact point for investors
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Consultancy on interviews (traditional and social media)
                                                </li>
                                            </ul>
                                        </div>

                                </div>
                                <div className=" col-md-6 mb-5">
                                    <div className="" role="tab">
                                        <h4 className="panel-title">
                                        C-Suite
                                        communications

                                        </h4>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Market communication training for senior management
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Preparation and consultancy on forums and presentations to investors
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Quarterly earnings speechwriting
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Speech drafting and consultancy on corporate transactions (M&As, divestitures and other corporate events)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Crisis management consultancy
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Consultancy on video message production
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-md-6 mb-5">
                                    <div className="" role="tab">
                                        <h4 className="panel-title">
                                        Structuring
                                        of policies, procedures and metrics

                                        </h4>
                                    </div>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Disclosure policy development
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Core functions mapping
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>IR flowchart creation (functions and methodology)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Definition of key performance indicators (KPIs)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Consultancy on corporate governance
                                                </li>
                                            </ul>
                                        </div>

                                </div>
                                <div className=" col-md-6 mb-5">
                                <div className="" role="tab">
                                        <h4 className="panel-title">

                                        Strategic
                                        core

                                        </h4>
                                    </div>
                                    <div>


                                        <ul className="list_style">
                                            <li><i className="fa fa-angle-right"></i>Investment thesis development
                                            </li>
                                            <li><i className="fa fa-angle-right"></i>Shaping of competitive advantage narrative
                                            </li>
                                            <li><i className="fa fa-angle-right"></i>Crafting of the history narrative
                                            </li>
                                            <li><i className="fa fa-angle-right"></i>Setting of the long-term guidance
                                            </li>
                                            <li><i className="fa fa-angle-right"></i>Communication style shaping (qualitative and quantitative mix)
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div className=" col-md-6 mb-5">
                                    <div className="" role="tab">
                                        <h4 className="panel-title">
                                            Communication and strategic disclosure

                                        </h4>
                                    </div>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Wires publishing strategy
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Distribution of announcements on quarterly earnings reports and conference calls
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Outreach to financial and specialized media
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Distribution list management
                                                </li>
                                            </ul>

                                    </div>
                                </div>
                                <div className=" col-md-6 mb-5">
                                    <div className="" role="tab">
                                        <h4 className="panel-title">
                                           IR Edge 3.0 Services
                                            </h4>

                                    </div>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Mailing up to 10,000 contacts
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Mail Analytics
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Translation services (Spanish-English)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Webcast logistics (MP3 editing and transcript)
                                                </li>
                                            </ul>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </section>
            
        </Layout>
    )
}

export default Contenido
